import { extractFirstSentence } from '@nploy/shared';

export const generateCompanyPageDescription = (
  {
    companyName,
    description,
    topJobRolesText,
    website,
  }: {
    companyName: string;
    description: string;
    topJobRolesText: string;
    website: string;
  },
  t,
) => {
  if (companyName) {
    const findSuitableText = t('meta.findASuitable');

    if (description && topJobRolesText) {
      const firstSentenceOfDescription = extractFirstSentence(description);

      return `${firstSentenceOfDescription} ${website}. ${topJobRolesText}.`;
    }

    return `${findSuitableText} ${companyName}.`;
  }

  return '';
};
