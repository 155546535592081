import {
  ImageProps,
  ImageStyle,
  StyleProp,
  TextStyle,
  ViewStyle,
} from 'react-native';

export enum UserPreferencesNames {
  OpenToWork = 'onboarding.openToWork',
  Explore = 'onboarding.explore',
}

export interface IUserPreferencesProps {
  title: UserPreferencesNames;
  subtitle: string;
  onPressHandler: (key: UserPreferencesNames) => Promise<any>;
  image?: ImageProps;
  subtitleStyle?: StyleProp<TextStyle>;
  styles?: StyleProp<ViewStyle>;
  imageStyles?: StyleProp<ImageStyle>;
}
