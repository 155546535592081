import moment from 'moment';
import { Comment } from './comment';
import { Company } from './company';
import { replaceSpacesWithUnderscores } from './helpers/replaceSpacesWithUnderscores';
import { Reaction } from './reaction';
import { User as IUser } from './user';
import { IVideoMeeting } from './video-meeting';

export enum EventTypesEnum {
  Upcoming = 'Upcoming',
  Online = 'Online',
  User = 'Your events',
}

export interface IEventCountry {
  value: string;
  label: string;
}

export interface Event {
  id: string;
  backgroundPhoto: string;
  date: Date;
  dateExpired: Date;
  createdAt: Date;
  description: string;
  location: string;
  logo: string;
  title: string;
  usersList: string[];
  usersData: Omit<
    IUser,
    'unseenEventsUpdates' | 'unseenGroupsUpdates' | 'unreadMessagesCount'
  >[];
  reactions?: Reaction;
  companyId?: string;
  companyData?: Company;
  occupationId?: string;
  comments?: Comment[];
  videoMeetingData?: IVideoMeeting;
  lastJoinedUsers?: Omit<
    IUser,
    'unseenEventsUpdates' | 'unseenGroupsUpdates' | 'unreadMessagesCount'
  >[];
  country?: string[] | null;
}

export interface SearchEventInfo {
  id: string;
  date: Date;
  dateExpired: Date;
  logo?: string;
  title: string;
}

export interface PersistedEvent {
  id: number;
  firestoreId: string;
  Date: string;
  Title: string;
  dateExpired: string;
  logo?: {
    url: string;
  };
  ocupation: string;
  entityName: string;
}

// ==========================================================
//  Is current logged in user attended to event
// ==========================================================
export const isAttendedUser = (usersList: string[], userId: string): boolean =>
  usersList.includes(userId);

// ==========================================================
//  Is event saved by current logged in user
// ==========================================================
export const isEventSavedByUser = (user: IUser, eventId: string): boolean =>
  !!user?.eventsFavoriteList?.includes(eventId);

// ==========================================================
//  Generate url for event sharing
// ==========================================================
export const generateEventDetailsUrl = (event: Event): string => {
  if (!event) return '';

  const { title, id } = event;

  if (id && title) {
    return `${
      process.env.CANDIDATES_WEB_URL
    }/event/${id}/${replaceSpacesWithUnderscores(title)}`;
  }

  return '';
};

// ==========================================================
//  Сheck if the event has started video meeting
// ==========================================================
export const isVideoMeetingEventStarted = (
  dateEventStart: Date,
  dateEventExpired: Date,
): boolean => {
  const dateStart = moment(dateEventStart);
  const dateExpired = moment(dateEventExpired);
  const dateNow = moment();

  if (
    dateNow.isBetween(dateStart.subtract(10, 'minutes'), dateStart) ||
    dateNow.isBetween(dateStart, dateExpired)
  ) {
    return true;
  }

  return false;
};
