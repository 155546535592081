import { countryCurrency as COUNTRY_CURRENCY } from '@nploy/shared';
import { VOLUNTARY_WORK_EMPLOYMENT_TYPE_ID } from 'constants/index';

const isVoluntaryWorkType = (jobEmploymentTypes) =>
  Array.isArray(jobEmploymentTypes) &&
  jobEmploymentTypes.find(({ id }) => id === VOLUNTARY_WORK_EMPLOYMENT_TYPE_ID);

const isSalaryInUsersRange = ({
  jobSalaryMin,
  jobSalaryMax,
  userSalaryMin,
  userSalaryMax,
  jobSalaryMatch,
}) =>
  ((jobSalaryMax >= userSalaryMin && jobSalaryMax <= userSalaryMax) ||
    (jobSalaryMin >= userSalaryMin && jobSalaryMin <= userSalaryMax) ||
    jobSalaryMin === null ||
    jobSalaryMax === null) &&
  jobSalaryMatch;

const generateSalaryText = (
  {
    userSalaryMin,
    userSalaryMax,
    userCountry,
    jobSalaryMin,
    jobSalaryMax,
    jobCurrency,
    jobSalaryRangeHidden,
    jobSalaryMatch,
    jobEmploymentTypes,
  },
  t,
) => {
  if (isVoluntaryWorkType(jobEmploymentTypes))
    return t('job.employmentTypes.Voluntary Work');

  if (jobSalaryRangeHidden) {
    if (
      (COUNTRY_CURRENCY[userCountry] === jobCurrency &&
        isSalaryInUsersRange({
          jobSalaryMin,
          jobSalaryMax,
          userSalaryMin,
          userSalaryMax,
          jobSalaryMatch,
        })) ||
      jobSalaryMatch
    )
      return t('job.salaryExpectationsMatched');
  } else {
    if (jobSalaryMin && jobSalaryMax && jobCurrency) {
      if (jobSalaryMin === jobSalaryMax) {
        return `${jobSalaryMin} ${jobCurrency} ^${t('job.net')}`;
      }
      return `${jobSalaryMin} - ${jobSalaryMax} ${jobCurrency} ^${t(
        'job.net',
      )}`;
    }
  }

  return '';
};

export default generateSalaryText;
