import jobPageTitleStrategy from './/generators/generateJobPageTitle';
import cityPageTitleStrategy from './generators/generateCitySEOPageTitle';
import { generateCompanyPageDescription } from './generators/generateCompanyPageDescription';
import companyPageTitleStrategy from './generators/generateCompanyPageTitle';
import jobCategoryPageTitleStrategy from './generators/generateJobCategorySEOPageTitle';
import salaryTextStrategy from './generators/generateSalaryText';

export {
  jobCategoryPageTitleStrategy,
  cityPageTitleStrategy,
  jobPageTitleStrategy,
  companyPageTitleStrategy,
  generateCompanyPageDescription,
  salaryTextStrategy,
};

const defaultTranslate = (text) => text;

const generateTextWithStrategy = (strategy, context, t = defaultTranslate) => {
  if (!strategy || typeof strategy !== 'function') return '';

  return strategy(context, t);
};

export default generateTextWithStrategy;
