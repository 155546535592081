import React, { FC } from 'react';
import { Typography } from '@mui/material';
import generateTextWithStrategy, {
  salaryTextStrategy,
} from 'utils/generateTextWithStrategy';
import useTranslate from 'utils/i18n/useTranslate';
import { IJobSalaryText } from './job-salary-text.interface';

export const JobSalaryText: FC<IJobSalaryText> = ({
  userSalaryMin,
  userSalaryMax,
  userCountry,
  jobSalaryMin,
  jobSalaryMax,
  jobCurrency,
  jobSalaryRangeHidden,
  jobSalaryMatch,
  jobEmploymentTypes,
  netTextSx,
}) => {
  const { t } = useTranslate();

  return (
    <>
      {generateTextWithStrategy(
        salaryTextStrategy,
        {
          userSalaryMin,
          userSalaryMax,
          userCountry,
          jobSalaryMin,
          jobSalaryMax,
          jobCurrency,
          jobSalaryRangeHidden,
          jobSalaryMatch,
          jobEmploymentTypes,
        },
        t,
      )
        .split('^')
        .map((textPart: string, index: number) => (
          <Typography
            variant="h4"
            key={textPart}
            sx={{ display: 'inline-block', ...(index !== 0 && netTextSx) }}
          >
            {textPart}
          </Typography>
        ))}
    </>
  );
};

JobSalaryText.defaultProps = {
  userSalaryMin: null,
  userSalaryMax: null,
  userCountry: null,
  jobSalaryMin: null,
  jobSalaryMax: null,
  jobCurrency: null,
  jobSalaryRangeHidden: false,
  jobEmploymentTypes: null,
};
