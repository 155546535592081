const generateCompanyPageTitle = ({ companyName, country }) => {
  if (companyName) {
    if (!country) {
      return `${companyName} | nPloy Job Search`;
    }

    return `${companyName} Jobs in ${country} | nPloy Job Search`;
  }

  return '';
};

export default generateCompanyPageTitle;
