const generateCitySEOPageTitle = ({ jobCategory, country, city }, t) => {
  if (jobCategory && country && city) {
    const countryTranslated = t(`SEOTables.${country}`);
    const jobsInTranslated = t("SEOTables.jobsIn");

    return `${jobCategory} ${jobsInTranslated} ${city}, ${countryTranslated}`;
  }

  return "";
};

export default generateCitySEOPageTitle;
