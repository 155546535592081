export interface IBraintreeResponse {
  _httpStatus: number;
  creditCards: IBraintreeResponseCreditCards[];
}

export enum CreditCartEnum {
  visa = 'Visa',
  mastercard = 'MasterCard',
  amex = 'American Express',
  maestro = 'Maestro',
  discover = 'Discover',
}

export interface IBraintreeResponseCreditCardsBinData {
  prepaid: string;
  healthcare: string;
  debit: string;
  durbinRegulated: string;
  commercial: string;
  payroll: string;
  issuingBank: string;
  countryOfIssuance: string;
  productId: string;
}

export interface IBraintreeResponseCreditCardsDetails {
  expirationMonth: string;
  expirationYear: string;
  bin: string;
  cardType: CreditCartEnum;
  lastFour: string;
  lastTwo: string;
}

export interface IBraintreeResponseCreditCards {
  binData: IBraintreeResponseCreditCardsBinData;
  consumed: boolean;
  description: string;
  nonce: string;
  details: IBraintreeResponseCreditCardsDetails;
  type: string;
}

export interface IBraintreeFieldError {
  field: string;
  fieldErrors: {
    code: string;
    field: string;
    message: string;
  }[];
}
