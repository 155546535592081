import moment from 'moment';

export interface ICalculateExperienceData {
  startedAt: string;
  endedAt: string | null;
}

export interface ICalculateExperience {
  years: number;
  months: number;
}

export const calculateExperience = (
  calculateExperienceData: ICalculateExperienceData,
): ICalculateExperience | null => {
  if (calculateExperienceData) {
    const months = Math.round(
      (calculateExperienceData.endedAt
        ? moment(calculateExperienceData.endedAt)
        : moment()
      ).diff(calculateExperienceData.startedAt, 'month', true),
    );

    const years = Math.floor(Math.ceil(months) / 12);
    const monthsWithoutYears = Math.floor(Math.ceil(months) % 12);

    return {
      years,
      months: monthsWithoutYears,
    };
  }

  return null;
};
