export const filtersValuesFields = {
  cities: 'cities',
  country_id: 'country_id',
  employment_levels: 'employment_levels',
  employment_types: 'employment_types',
  experience_years_max: 'experience_years_max',
  experience_years_min: 'experience_years_min',
  jobCategories: 'jobCategories',
  keywords: 'keywords',
  salary_max: 'salary_max',
  salary_min: 'salary_min',
  work_models: 'work_models',
};
