import {
  compareArrayDisregardPosition,
  compareArraysById,
} from './compareArrays';

export const compareFiltersValues = (valuesA, valuesB) => {
  // Test for null and undefined
  if (valuesA === valuesB) return true;

  // Test for something is falsy
  if (!valuesA || !valuesB) return false;

  // Test for {} (empty object)
  if (
    Object.keys(valuesA).length === 0 &&
    valuesA.constructor === Object &&
    Object.keys(valuesB).length === 0 &&
    valuesB.constructor === Object
  )
    return true;

  // Test primitives
  // country_id
  if (valuesA.country_id !== valuesB.country_id) return false;

  // experience_years_min
  if (valuesA.experience_years_min !== valuesB.experience_years_min)
    return false;

  // experience_years_max
  if (valuesA.experience_years_max !== valuesB.experience_years_max)
    return false;

  // salary_min
  if (valuesA.salary_min !== valuesB.salary_min) return false;

  // salary_max
  if (valuesA.salary_max !== valuesB.salary_max) return false;

  // Test arrays
  // employment_levels
  if (
    !compareArrayDisregardPosition(
      valuesA.employment_levels,
      valuesB.employment_levels,
    )
  )
    return false;

  // employment_types
  if (
    !compareArrayDisregardPosition(
      valuesA.employment_types,
      valuesB.employment_types,
    )
  )
    return false;

  // keywords
  if (!compareArrayDisregardPosition(valuesA.keywords, valuesB.keywords))
    return false;

  // jobCategories
  if (
    !compareArrayDisregardPosition(valuesA.jobCategories, valuesB.jobCategories)
  )
    return false;

  // Test arrays with objects with ids
  // cities
  if (!compareArraysById(valuesA.cities, valuesB.cities, true)) {
    return false;
  }
  return compareArraysById(valuesA.work_models, valuesB.work_models, true);
};
