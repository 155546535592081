import { IFiltersValuesDto } from '@nploy/ui-infrastructure';

export const transformFiltersToExternalJobs = ({
  country_id,
  employment_levels,
  employment_types,
  experience_years_max,
  experience_years_min,
  work_models,
  keywords,
  jobCategories,
}: IFiltersValuesDto) => ({
  country_id,
  employment_levels,
  employment_types,
  ...(experience_years_max && { experience_years_max }),
  ...(experience_years_max && { experience_years_min }),
  keywords,
  ...(work_models && { work_models: work_models.map(({ id }) => id) }),
  job_occupations: (jobCategories || []).map((id) => id),
});
export const transformFiltersValuesToSend = (
  {
    cities,
    country_id,
    employment_levels,
    employment_types,
    experience_years_max,
    experience_years_min,
    jobCategories,
    keywords,
    salary_min,
    salary_max,
    work_models,
  }: IFiltersValuesDto,
  withSalaryMax = false,
) => ({
  cities: cities?.map(({ id }) => id),
  country_id,
  employment_levels,
  employment_types,
  ...(experience_years_max && { experience_years_max }),
  ...(experience_years_max && { experience_years_min }),
  job_occupations: jobCategories,
  keywords,
  ...(salary_min && { salary_min }),
  ...(salary_max && withSalaryMax && { salary_max }),
  ...(work_models && { work_models: work_models.map(({ id }) => id) }),
});
