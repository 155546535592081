import { Event } from './event';
import { Group } from './group';
import { User } from './user';

export enum CandidateNotificationTypes {
  matchRequest = 'match_request',
  match = 'match',
  postComment = 'post_comment',
  postLike = 'post_like',
  commentComment = 'comment_comment',
  likeCommentToComment = 'like_comment_comment',
  commentLike = 'comment_like',
  event = 'event',
  event_comment_like = 'event_comment_like',
}

export interface ICandidateNotification {
  id: number | string;
  title?: string;
  body: string;
  isRead?: number;
  url: string;
  type?: CandidateNotificationTypes;
  dialogId?: number;
  userId?: string;
  authorId?: string;
  createdAt?: Date | string;
  authorData?: User;
  eventData?: Event;
  groupData?: Group;
  groupId?: string;
  eventId?: string;
}
