import moment from 'moment';
import { calculateExperience } from './experience';

export interface IWorkExperience {
  candidateId: number;
  companyName: string;
  companyWebsiteUrl: string | null;
  createdAt: string;
  description: string | null;
  endedAt: string | null;
  hideWorkExperience: number;
  id: number;
  position: string;
  startedAt: string;
  updatedAt: string | null;
  startDateInvalid?: boolean;
  endDateInvalid?: boolean;
}
export interface ICalculatePositionExperience {
  years: number;
  months: number;
}

export const findTalentsLastWorkExperience = (
  experiences: IWorkExperience[],
): IWorkExperience | null => {
  if (!experiences.length) return null;

  const lastWorkExperience = experiences.find(
    (experience) => !experience.endedAt,
  );

  if (!lastWorkExperience) {
    const sortedExperiencesByEndDate = [...experiences].sort(
      (a, b) => moment(b.endedAt).valueOf() - moment(a.endedAt).valueOf(),
    );

    return sortedExperiencesByEndDate[0];
  }

  return lastWorkExperience;
};

export const isDatePresent = (date: string): boolean =>
  moment().diff(date, 'days') === 0;

export const workExperienceCompanyDataShouldBeHidden = (
  workExperience: IWorkExperience | null,
): boolean => {
  if (!workExperience) return true;

  const experience = calculateExperience({
    startedAt: workExperience.startedAt,
    endedAt: workExperience.endedAt,
  });

  return (
    !!workExperience.hideWorkExperience ||
    ((!workExperience.endedAt || isDatePresent(workExperience.endedAt)) &&
      (experience?.years || 0) < 2)
  );
};

export const totalExperience = (work: IWorkExperience[]): string => {
  const result = work
    .map((item: IWorkExperience) =>
      calculateExperience({
        startedAt: item.startedAt,
        endedAt: item.endedAt,
      }),
    )
    .reduce(
      (total, item) => ({
        years: (total?.years || 0) + (item?.years || 0),
        months: (total?.months || 0) + (item?.months || 0),
      }),
      { years: 0, months: 0 },
    );

  const yearsAll = Math.floor(Math.ceil(result!.months) / 12) + result!.years;
  const monthsWithoutYears = Math.floor(Math.ceil(result!.months) % 12);
  return `${yearsAll} yr ${monthsWithoutYears} mos`;
};
