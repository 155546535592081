import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SimpleChip } from '@nploy/ui/web';
import classnames from 'classnames';

const JobChips = ({ blackItems, greenItems, detailedView }) => {
  const classes = useStyles();

  return (
    <>
      {/* Black Items */}
      {!!blackItems.length && (
        <Box
          className={classnames(
            classes.container,
            !detailedView && classes.skillsClamped,
          )}
        >
          {blackItems.map(({ name, id }) => (
            <SimpleChip
              key={id}
              label={name}
              variant="outlined"
              color={detailedView ? 'secondary' : 'default'}
              mr={detailedView ? 1 : 0.5}
              mb={detailedView ? 1 : 0.5}
            />
          ))}
        </Box>
      )}

      {/* GreenItems */}
      {!!greenItems.length && (
        <Box className={classnames(classes.container)}>
          {greenItems.map(({ name, id }) => (
            <SimpleChip
              key={id}
              label={name}
              variant="outlined"
              color="primary"
              mr={detailedView ? 1 : 0.5}
              mb={detailedView ? 1 : 0.5}
            />
          ))}
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  skillsClamped: {
    maxHeight: 72,
    overflow: 'hidden',
  },
}));

JobChips.propTypes = {
  blackItems: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  ),
  greenItems: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  ),
  detailedView: PropTypes.bool,
};

JobChips.defaultProps = {
  blackItems: [],
  greenItems: [],
  detailedView: false,
};

export default JobChips;
