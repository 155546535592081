import { NavigationProp, ParamListBase } from '@react-navigation/native';
import moment from 'moment';
import { CreatorData } from './creator-data';
import { Reaction } from './reaction';
import { SortPostByEnum } from './sort-post';
import { User } from './user';

export interface Comment {
  id: string;
  comment: string;
  comments?: Comment[];
  creatorId: string;
  creatorData?: CreatorData;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  reactions?: Reaction;
  parentCommentsIds: string[];
  likesCount: number;
}

export interface CommentCardProps {
  comment: Comment;
  rootEntityId: string; // can be an ID of Event or Post for example
  currentUser: User;
  likeComment: (
    rootEntityId: string,
    commentId: string,
    isLiked: boolean,
    parentCommentsIds: string[],
  ) => void;
  onCreateCommentToCommentPress: (comment: Comment) => void;
  navigation: NavigationProp<ParamListBase>;
  onEditCommentToCommentPress: (comment: Comment) => void;
  onCreatePostCommentPress: () => void;
  isEvent?: boolean;
}

export interface ExtendedCommentListProps {
  commentsArray: Comment[];
  rootEntityId: string; // can be an ID of Event or Post for example
  currentUser: User;
  navigation: NavigationProp<ParamListBase>;
  likeComment: (
    rootEntityId: string,
    commentId: string,
    isLiked: boolean,
    parentCommentsIds: string[],
  ) => void;
  onCreateCommentToCommentPress: (comment: Comment) => void;
  onSort?: (sortBy: SortPostByEnum) => void;
  onEditCommentToCommentPress?: (comment: Comment) => void;
  onCreatePostCommentPress?: () => void;
  isEvent?: boolean;
}

// ==========================================================
//  Is comment liked by current logged in user
// ==========================================================
export const isCommentLikedByUser = (
  comment: Comment,
  currentUserId: string,
): boolean => !!comment?.reactions?.like?.includes(currentUserId);

// ==========================================================
//  Sort comments array by most liked
// ==========================================================
export const sortCommentsByMostLiked = (comments: Comment[]): Comment[] =>
  comments.sort(
    (a, b) =>
      (b?.reactions?.like?.length || 0) - (a?.reactions?.like?.length || 0),
  );

// ==========================================================
//  Sort comments array by most relevant
// ==========================================================
export const sortCommentsByMostRelevant = (comments: Comment[]): Comment[] =>
  comments.sort(
    (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf(),
  );
