import { Comment } from './comment';
import { CommentWithEvent } from './comment-to-event';
import { CreatorData } from './creator-data';
import { Group } from './group';
import { replaceSpacesWithUnderscores } from './helpers/replaceSpacesWithUnderscores';
import { Poll } from './poll';
import { Reaction } from './reaction';

export enum PostsTypesEnum {
  Posts = 'posts',
  Events = 'events',
}

export interface Post {
  id: string;
  creatorId: string | undefined;
  discussionGroup: number;
  createdAt: Date;
  updatedAt: Date | undefined;
  title: string;
  text: string;
  photo: string;
  likesCount: number;
  commentsCount: number;
  poll?: Poll;
  comments?: Comment[];
  commentsLength?: number;
  reactions?: Reaction;
  creatorData?: CreatorData;
  groupData?: Group;
  link?: string;
}

// ==========================================================
//  Is post liked by current logged in user
// ==========================================================
export const isPostLikedByUser = (
  post: Post | Comment,
  currentUserId: string,
): boolean => !!post?.reactions?.like?.includes(currentUserId);

// ==========================================================
//  Generate url for post sharing
// ==========================================================
export const generatePostDetailsUrl = (post: Post): string => {
  if (!post) return '';

  const { title, groupData, id } = post;
  const name = groupData?.name;

  if (name && title) {
    return `${
      process.env.CANDIDATES_WEB_URL
    }/post/${id}/${replaceSpacesWithUnderscores(title)}`;
  }

  return '';
};

export const generateCommentToEventDetailsUrl = (
  post: CommentWithEvent,
): string => {
  if (!post) return '';

  const { event, comment } = post;
  const eventId = event?.id;
  const eventTitle = event?.title;
  const commentId = comment.id;

  if (eventId && commentId && eventTitle) {
    return `${
      process.env.CANDIDATES_WEB_URL
    }/event/${eventId}/${replaceSpacesWithUnderscores(
      eventTitle,
    )}/comment/${commentId}`;
  }

  return '';
};
