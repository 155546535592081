import { Group } from './group';
import { replaceSpacesWithUnderscores } from './helpers/replaceSpacesWithUnderscores';

export interface Company {
  id: number;
  industry: string;
  name: string;
  logoUrl: string;
  websiteUrl: string;
}

export const generateClubUrl = (club: Group): string => {
  if (!club) return '';

  const { name, id } = club;

  if (id && name) {
    return `${
      process.env.CANDIDATES_WEB_URL
    }/group/${id}/${replaceSpacesWithUnderscores(name)}`;
  }

  return '';
};
