const generateJobPageTitle = (job) => {
  const roleName = job?.job_role?.name;
  if (!roleName) return "";

  const companyName = job.company?.name;
  if (!companyName) return roleName;

  return `${roleName} | ${companyName} Jobs`;
};

export default generateJobPageTitle;
