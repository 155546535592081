/* eslint-disable @typescript-eslint/naming-convention */
import useTranslation from 'next-translate/useTranslation';
import useSWR from 'swr';
import { axios } from '@nploy/shared';
import { FilterName, IFiltersValuesDto } from '@nploy/ui-infrastructure';
import { IWorkModel } from '@nploy/ui/domain';
import { filtersValuesFields } from 'constants/filters-values-fields';
import transformLocaleFormat from 'utils/i18n/transformLocaleFormat';
import {
  getFromLocalStorage,
  localStorageKeys,
  setToLocalStorage,
} from 'utils/localStorage';
import { transformFiltersValuesToSend } from 'utils/swr/helpers';
import { useAppDispatch } from 'hooks/*';
import { openFeedback } from 'store/reducers/feedbackReducer';
import { compareFiltersValues } from '../helpers/compareFiltersValues';
import { fetchData } from '../helpers/fetchData';
import {
  hasCountryIdChanged,
  resetSalaryOnCountryChange,
} from '../helpers/filtersValues';
import { shouldFetchIfHasToken } from '../helpers/shouldFetch';
import { useUser } from './useUser';

const initialData = {
  cities: [],
  country_id: '',
  employment_levels: [],
  employment_types: [],
  experience_years_max: 0,
  experience_years_min: 1,
  jobCategories: [],
  keywords: [],
  salary_max: 0,
  salary_min: 0,
  work_models: [],
};

const createAuthedUserFilters = (data) => ({
  country_id: data.country_id,
  salary_min: data.salary_min,
  salary_max: data.salary_max,
  cities: data.cities,
  keywords: data.keywords,
  experience_years_min: data.experience_years_min,
  experience_years_max: data.experience_years_max,
  // Change naming, leave jobCategories if it's a mutation
  jobCategories:
    data?.jobCategories || data?.job_occupations?.map((item) => item.id) || [],
  // Set default value if none is selected (id if any, item if does not have id property - is id itself)
  employment_levels: data?.employment_levels
    ? data.employment_levels.map((item) => item.id || item)
    : initialData.employment_levels,
  employment_types: data?.employment_types
    ? data.employment_types.map((item) => item.id || item)
    : initialData.employment_types,
  work_models: data?.work_models || [],
});

const createUnAuthedUserFilters = () => {
  const jobCategories = getFromLocalStorage(localStorageKeys.jobCategories);
  const country_id = getFromLocalStorage(localStorageKeys.country_id);
  const salary_min = getFromLocalStorage(localStorageKeys.salary_min);
  const salary_max = getFromLocalStorage(localStorageKeys.salary_max);
  const cities = getFromLocalStorage(localStorageKeys.cities);
  const keywords = getFromLocalStorage(localStorageKeys.keywords);
  const employment_levels = getFromLocalStorage(
    localStorageKeys.employment_levels,
  );
  const employment_types = getFromLocalStorage(
    localStorageKeys.employment_types,
  );
  const experience_years_min = getFromLocalStorage(
    localStorageKeys.experience_years_min,
  );
  const experience_years_max = getFromLocalStorage(
    localStorageKeys.experience_years_max,
  );
  const workModels = getFromLocalStorage(localStorageKeys.work_models);

  return {
    jobCategories: jobCategories || [],
    country_id: country_id || '',
    salary_min: salary_min || 0,
    salary_max: salary_max || 0,
    cities: cities || [],
    keywords: keywords || [],
    employment_levels: employment_levels || initialData.employment_levels,
    employment_types: employment_types || initialData.employment_types,
    experience_years_min:
      experience_years_min || initialData.experience_years_min,
    experience_years_max:
      experience_years_max || initialData.experience_years_max,
    work_models: workModels || initialData.work_models,
  };
};

const fetcher = async (url: string, lang: string) => {
  if (shouldFetchIfHasToken()) {
    const data = await fetchData(url, { locale: transformLocaleFormat(lang) });
    return createAuthedUserFilters(data);
  }
  return createUnAuthedUserFilters();
};

export const useFiltersValues = () => {
  const { lang } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    user: { isAuthed },
  } = useUser();

  const { data, error, mutate } = useSWR<IFiltersValuesDto>(
    'filters/general/get',
    (url) => fetcher(url, lang),
    {
      shouldRetryOnError: isAuthed,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      compare: compareFiltersValues,
    },
  );

  const mutateByField = (name: FilterName, value, revalidate = false) => {
    mutate({ ...data, [name]: value }, revalidate);
  };

  // Save values
  const saveFieldsWithAllFilters = async (
    dataToSave: Partial<IFiltersValuesDto>,
    callback = undefined,
  ) => {
    mutate({ ...data, ...dataToSave }, false);
    if (callback) callback();
    try {
      if (isAuthed) {
        await axios.post(
          'filters/general/store-all-filters',
          transformFiltersValuesToSend({ ...data, ...dataToSave }, true),
        );
      } else {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in dataToSave) {
          if (localStorageKeys[key]) {
            setToLocalStorage(localStorageKeys[key], dataToSave[key]);
          }
        }
      }
    } catch (err) {
      dispatch(openFeedback({ type: 'error', message: err.message }));
    } finally {
      mutate();
    }
  };

  const saveField = async ({
    field,
    value,
    callback,
    url,
    requestParams,
    storageKey,
  }) => {
    if (callback) callback();
    try {
      if (isAuthed) {
        mutateByField(field, value);
        await axios.post(url, requestParams);
      } else {
        setToLocalStorage(storageKey, value);
      }
    } catch (err) {
      dispatch(openFeedback({ type: 'error', message: err.message }));
    } finally {
      mutate();
    }
  };

  const saveJobCategories = (jobCategories: number[], callback = undefined) =>
    saveField({
      field: filtersValuesFields.jobCategories,
      value: jobCategories,
      callback,
      url: 'filters/job-occupations',
      requestParams: { job_occupations: jobCategories },
      storageKey: localStorageKeys.jobCategories,
    });

  const saveCountry = (country_id: string, callback = undefined) =>
    saveField({
      field: filtersValuesFields.country_id,
      value: country_id,
      callback,
      url: 'filters/country',
      requestParams: {
        country_id,
      },
      storageKey: localStorageKeys.country_id,
    });

  const saveSalary = async (
    salaryMin: number,
    salaryMax: number,
    callback = undefined,
  ) => {
    mutate(
      {
        ...data,
        [filtersValuesFields.salary_min]: salaryMin,
        [filtersValuesFields.salary_max]: salaryMax,
      },
      false,
    );
    if (callback) callback();
    try {
      if (isAuthed) {
        await axios.post('filters/salary', {
          salary_min: salaryMin,
          salary_max: salaryMax,
          country_id: data.country_id,
        });
      } else {
        setToLocalStorage(localStorageKeys.salary_min, salaryMin);
        setToLocalStorage(localStorageKeys.salary_max, salaryMax);
      }
    } catch (err) {
      dispatch(openFeedback({ type: 'error', message: err.message }));
    } finally {
      mutate();
    }
  };

  const saveWorkModels = async (workModels: IWorkModel[]) => {
    await saveFieldsWithAllFilters({ work_models: workModels });
  };

  const saveLocation = async (dataToSave, callback) => {
    if (hasCountryIdChanged(data, dataToSave)) {
      const newDataToSave = await resetSalaryOnCountryChange(dataToSave);
      return saveFieldsWithAllFilters(newDataToSave, callback);
    }
    return saveFieldsWithAllFilters(dataToSave, callback);
  };

  return {
    filtersValues: data || initialData,
    loading: !error && !data,
    error,
    mutateByField,
    mutate,
    saveJobCategories,
    saveCountry,
    saveSalary,
    saveLocation,
    saveFieldsWithAllFilters,
    initialFilters: initialData,
    saveWorkModels,
  };
};
