const generateJobCategorySEOPageTitle = ({ jobCategory, country }, t) => {
  if (jobCategory && country) {
    const countryTranslated = t(`SEOTables.${country}`);
    const jobsInTranslated = t("SEOTables.jobsIn");

    return `${jobCategory} ${jobsInTranslated} ${countryTranslated}`;
  }

  return "";
};

export default generateJobCategorySEOPageTitle;
